/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";

const CONNECTION_URL = "https://whalebettor.com/api/endpoint/crypto-news";
/**
 * Render react app with redux store
 */

const IndustryNewsFeedApp: React.FC = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currencyDetails, setCurrencyDetails] = useState([]);
  const [filterCoinData, setFilterCoinData] = useState([]);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title", "domain"]);
  const dropDownValue = currencyDetails.map((response: any) => ({
    value: response.logoName,
    label: (
      <>
        <div className="flex items-center">
          <img
            src={response.logoUrl}
            className="w-6 rounded-full"
            alt={response.logoName}
          />
          <span style={{ paddingLeft: "10px" }}>{response.logoName}</span>
        </div>
      </>
    ),
  }));

  const fetchData = async () => {
    return await axios.get(CONNECTION_URL).then((response: any) => {
      return response.data;
    });
  };

  useEffect(() => {
    const fetching = async () => {
      const data = await fetchData();
      if (data) {
        setLoading(true);
        setData(data);
        filterByDropDown(data);
      } else {
        setLoading(true);
        setError(true);
      }
      setData(data);
    };
    fetching();
  }, []);

  function filterByDropDown(results: any[]) {
    const currencies = [];
    for (let i = 0; i < results.length; i++) {
      if (results[i].currencies) {
        var currency = results[i].currencies;
        currencies.push(currency);
      }
    }
    let currencyCount = [];
    let logoNameList = [];
    for (let j = 0; j < currencies.length; j++) {
      if (currencies[j].length >= 1) {
        for (let k = 0; k < currencies[j].length; k++) {
          var temp = [];
          temp = currencies[j];
          currencyCount.push(temp[k]);
        }
      }
    }
    for (let l = 0; l < currencyCount.length; l++) {
      var logoName = [];
      var logoUrl = [];
      logoName = currencyCount[l].code;
      logoUrl = currencyCount[l].logo;
      logoNameList.push({
        logoName: logoName,
        logoUrl: logoUrl,
      });
    }

    // @ts-ignore
    setCurrencyDetails(_.uniqBy(logoNameList, "logoName"));
  }

  const handleSelect = async (e: any) => {
    let filterD: any = [];

    if (e && e.length > 0) {
      let temp = data.filter((item: any) => Boolean(item.currencies));
      temp.map((value: any) => {
        value.currencies.some((coin: any) =>
          e.some((a: { value: any }) => a.value === coin.code)
        ) && filterD.push(value);
      });
      setFilterCoinData(filterD);
    } else {
      const data = await fetchData();
      setFilterCoinData(data);
    }
  };

  function search(items: any[]) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  const style = {
    control: (base: any) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  if (error) {
    // @ts-ignore
    return (
      <div className="flex gap-4 items-center justify-center align-middle w-full h-full">
        <div className="text-xl text-gray-900">
          <span className="font-semibold">Uh oh!</span>
          <span className="ml-2">
            There was an issue. We'll get right on it!
          </span>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    );
  } else if (!loading) {
    return (
      <div className="flex items-center h-screen justify-center mx-auto">
        <p className="text-dark-700 text-lg">Loading...</p>
      </div>
    );
  } else {
    // @ts-ignore
    return (
      <React.Fragment>
        <div className="font-sans">
          <div className="py-8 px-4 bg-white">
            <div className="w-full xl:w-3/4 bg-white md:flex items-center md:space-x-4 space-x-0">
              <input
                className="md:w-3/4 w-full py-4 border-2 border-gray-300 px-6 text-gray-700 leading-tight focus:outline-none
                        focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                id="search"
                type="text"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
              <Select
                styles={style}
                className="md:w-1/4 w-3/4 py-2 md:mt-0 mt-3 border-2 border-gray-300 text-gray-700 leading-tight focus:outline-none
                        focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                closeMenuOnSelect={false}
                isMulti
                options={dropDownValue}
                placeholder="All Tokens"
                onChange={handleSelect}
              />
            </div>
          </div>
          <div className="list-items md:pt-8 pb-8 px-4 w-full">
            {search(data).length > 0 ? (
              search(filterCoinData.length > 0 ? filterCoinData : data).map(
                (item) => (
                  <div key={item.id}>
                    <div className="flex justify-left mt-3">
                      <div
                        className="w-full xl:w-3/4 hover:shadow-menu bg-light border-2
                                             border-gray-150 p-md-5 p-3"
                      >
                        <a href={item.url} target="_blank" rel="noreferrer">
                          <div id="header" className="flex">
                            <div id="body" className="flex flex-col">
                              <div className="md:flex items-center">
                                <h6 id="time" className="text-gray-500">
                                  {moment(item.published_at)
                                    .startOf("hour")
                                    .fromNow()}
                                </h6>
                                <h4
                                  id="name"
                                  className="text-xl capitalize font-bold md:ml-2"
                                >
                                  {item.title}
                                </h4>
                              </div>
                              <div className="md:flex flex-wrap mt-5 md:space-x-4 space-x-0">
                                <h6 id="site" className="text-gray-500">
                                  {item.domain}
                                </h6>
                                <div className="flex space-x-3">
                                  {item.currencies &&
                                    item.currencies.map(
                                      (currencyDetails: any, index: number) => {
                                        return (
                                          <div key={index}>
                                            <div className="has-tooltip">
                                              <span className="tooltip rounded shadow-lg p-1 bg-black text-white text-xs -mt-7">
                                                {currencyDetails.code}
                                              </span>
                                              <img
                                                alt={currencyDetails.code}
                                                className="w-6 rounded-full"
                                                src={currencyDetails.logo}
                                              />
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  {/*<img alt="img-name" className="w-6 rounded-full"*/}
                                  {/*     src="https://picsum.photos/seed/picsum/200"/>*/}
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>{" "}
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="text-dark-700 text-lg">No results found</div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default IndustryNewsFeedApp;
