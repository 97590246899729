import React from "react";
import ReactDOM from "react-dom";
import IndustryNewsFeed from "./industry-news-feed";
import Terminology from "./terminology";
import CryptoMiningRigs from "./crypto_mining_rigs";

const widgetsMap = [
  { rootid: "industry-newsfeed-root", reactComponent: <IndustryNewsFeed /> },
  { rootid: "terminology-guide-root", reactComponent: <Terminology /> },
  { rootid: "crypto-mining-rigs-root", reactComponent: <CryptoMiningRigs /> },
];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  ReactDOM.render(<CryptoMiningRigs />, document.getElementById("root"));
} else {
  widgetsMap.map(({ rootid, reactComponent }) => {
    if (document.getElementById(rootid)) {
      ReactDOM.render(reactComponent, document.getElementById(rootid));
    }
    return true;
  });
}
