/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import _ from "lodash";

/**
 * Render react app with redux store
 */

const TerminologyApp: React.FC = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["Term", "Definition"]);

  useEffect(() => {
    fetch("https://whalebettor.com/api/table/Terminology%20Guide")
      .then((res) => res.json())
      .then(
        (result) => {
          const apiResponse = _.sortBy(result, ["Term"]);
          setIsLoaded(true);
          // @ts-ignore
          setItems(apiResponse);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  function search(items: any[]) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  if (error) {
    // @ts-ignore
    return <>{error.message}</>;
  } else if (!isLoaded) {
    return (
      <div className="flex items-center h-screen justify-center mx-auto">
        <p className="text-dark-700 text-lg">Loading...</p>
      </div>
    );
  } else {
    // @ts-ignore
    return (
      <React.Fragment>
        <div className="font-sans">
          <div className="py-8 px-4 ">
            <div className="w-full  flex justify-center md:space-x-4 space-x-0">
              <input
                className="md:w-2/4 w-full py-4 border-2 border-gray-300 px-6 text-gray-700 leading-tight  focus:outline-none border-2
                                    focus:ring-2 focus:ring-gray-300 focus:border-transparent"
                id="search"
                type="text"
                placeholder="Search terminology"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
          </div>
          <div className="list-items md:pt-3 pb-8 px-4 w-full">
            {search(items).length > 0 ? (
              search(items).map((item: any, index: number) => (
                <div key={index}>
                  <div className="flex justify-center mt-3">
                    <div className="w-full xl:w-3/4 shadow-menu md:p-4 p-3">
                      <div id="header" className="flex">
                        <div id="body" className="flex flex-col">
                          <div className="md:flex items-center">
                            <h4
                              id="name"
                              className="text-xl capitalize font-bold"
                            >
                              {item.Term}
                            </h4>
                          </div>
                          <div className="md:flex flex-wrap mt-5 md:space-x-4 space-x-0">
                            <h6 id="site" className="text-gray-500">
                              {item.Definition}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-dark-700 text-lg text-center">
                No results found
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
};

export default TerminologyApp;
