import React from "react";
import IndustryNewsFeedApp from "./App";
import { Helmet } from "react-helmet";
import "./styles.css";
/**
 * Render react app with redux store
 */

const IndustryNewsFeed: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Industry News Feed</title>
      </Helmet>
      <IndustryNewsFeedApp />
    </React.Fragment>
  );
};

export default IndustryNewsFeed;
