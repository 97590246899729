import React from "react";
import TerminologyApp from "./App";
import { Helmet } from "react-helmet";
import "./styles.css";
/**
 * Render react app with redux store
 */
const Terminology: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Terminology</title>
      </Helmet>
      <TerminologyApp />
    </React.Fragment>
  );
};

export default Terminology;
