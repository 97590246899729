import React, { useState } from "react";
import axios from "axios";
import humanNumber from "human-number";

const CONNECTION_URL = "https://whalebettor.com/api/v2/crypto/profitability?";

const CryptoMiningRigs: React.FC = () => {
  const [selected, setSelectedClient] = useState<
    "H" | "KH" | "MH" | "GH" | "TH"
  >("TH");
  const [tab, setTab] = useState<
    "BTC" | "ETH" | "XMR" | "ZEC" | "DASH" | "LTC" | "ETC"
  >("BTC");
  const [currency, setCurrency] = useState<string>('AUD');
  const [currencyRate, setCurrencyRate] = useState<number>(1);
  const [currencyRates, setCurrencyRates] = useState<any>([]);
  const [priceUSD, setPriceUSD] = useState<number>(52285);
  const [color, setColor] = useState<any>("profit");
  const [borderColor, setBorderColor] = useState<any>("green");
  const [hashingPower, setHashingPower] = useState<any>(0);
  const [powerConsumption, setPowerConsumption] = useState<number>(0);
  const [costPerKWH, setCostPerKWH] = useState<number>(0.115);
  const [poolFee, setPoolFee] = useState<number>(1.5);
  const [btcPrice, setBtcPrice] = useState(0);
  const [btcCurrencyPrice, setBtcCurrencyPrice] = useState(0);
  const [btcData, setBtcData] = useState({
    profitInDayUSD: 0,
    profitInWeekUSD: 0,
    profitInMonthUSD: 0,
    profitInYearUSD: 0,
    minedDay: 0,
    minedWeek: 0,
    minedMonth: 0,
    minedYear: 0,
    minedDayPoolFee: 0,
    minedWeekPoolFee: 0,
    minedMonthPoolFee: 0,
    minedYearPoolFee: 0,
    powerCostDay: "",
    powerCostMonth: "",
    powerCostWeek: "",
    powerCostYear: "",
    poolFees: "",
    profitRatioPerDay: 0,
  });

  const disclaimer = "These calculations rely on third party data and Mining Store is not responsible for the accuracy of such data. The data is also based on today's data and used to make projections for the future. Users should not rely on this information to make financial decisions and should always do your own research before purchasing products from Mining Store.";

  // const [profitInMonthUSD, setProfitPerMonth] = useState();
 
  React.useEffect(() => {
    setCurrencyRate(currencyRates[currency] || 1);
  }, [currency]);

  const formatDollars = (dollars: number) => {
    const formatter = new Intl.NumberFormat("en-AU", {
      style: "currency",
      currency: 'AUD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formatter.format(dollars);
  };

  const fetch = async (
    _hashingValue: any = 0,
    _powerConsumption: any = 0,
    _costPerKWH: any = 0,
    _poolFee: any = 0
  ) => {
    let hashingValue = _hashingValue;
    switch (selected) {
      case "H":
        hashingValue = _hashingValue;
        break;
      case "KH":
        hashingValue = `${_hashingValue}000`;
        break;
      case "MH":
        hashingValue = `${_hashingValue}000000`;
        break;
      case "GH":
        hashingValue = `${_hashingValue}000000000`;
        break;
      case "TH":
        hashingValue = `${_hashingValue}`;
        break;
    }
    return await axios
      .get(
        `${CONNECTION_URL}terahash=${hashingValue}&daily_kilowatt_hours=${_powerConsumption}&cost_per_kwh=${costPerKWH}`
      )
      .then((response: any) => {
        setCurrencyRates(response.data.currencies);
        console.log(response.data);
        return response.data;
      });
  };

  React.useEffect(() => {
    if (
      Math.sign(btcData.profitInDayUSD) === -1 ||
      Math.sign(btcData.profitInWeekUSD) === -1 ||
      Math.sign(btcData.profitInMonthUSD) === -1 ||
      Math.sign(btcData.profitInYearUSD) === -1
    ) {
      setColor("loss");
      setBorderColor("red");
    } else {
      setColor("profit");
      setBorderColor("green");
    }
  }, [
    btcData.profitInDayUSD,
    btcData.profitInMonthUSD,
    btcData.profitInWeekUSD,
    btcData.profitInYearUSD,
  ]);
  React.useEffect(() => {
    const fetching = async () => {
      const data = await fetch(
        hashingPower,
        powerConsumption,
        costPerKWH,
        poolFee
      );
      let btc = { ...btcData };
      if (data) {
        btc.minedDay = data.minedDay;
        btc.minedWeek = data.minedWeek;
        btc.minedMonth = data.minedMonth;
        btc.minedYear = data.minedYear;
        btc.profitInDayUSD = (currencyRate * data.profitInDayUSD);
        btc.profitInWeekUSD = (currencyRate * data.profitInWeekUSD);
        btc.profitInMonthUSD = (currencyRate * data.profitInMonthUSD);
        btc.profitInYearUSD = (currencyRate * data.profitInYearUSD);
        btc.powerCostDay = (currencyRate * data.powerCostDailyAUD).toFixed(4);
        btc.powerCostWeek = (currencyRate * data.powerCostWeeklyAUD).toFixed(4);
        btc.powerCostMonth = (currencyRate * data.powerCostMonthlyAUD).toFixed(4);
        btc.powerCostYear = (currencyRate * data.powerCostYearlyAUD).toFixed(4);
        btc.minedDayPoolFee = currencyRate * (data.revenueInDayUSD.toFixed(4) / priceUSD) * (poolFee / 100) * priceUSD;
        btc.minedWeekPoolFee = currencyRate * (data.revenueInWeekUSD.toFixed(4) / priceUSD) * (poolFee / 100) * priceUSD;
        btc.minedMonthPoolFee = (data.revenueInMonthUSD.toFixed(4) / priceUSD) * (poolFee / 100) * priceUSD;
        btc.minedYearPoolFee = (data.revenueInYearUSD.toFixed(4) / priceUSD) * (poolFee / 100) * priceUSD;

        // Daily profit ratio = profit / cost
        btc.profitRatioPerDay =
          (100 * data.profitInDayUSD) / data.powerCostDailyAUD;
      }
      setBtcData({ ...btc });
      setBtcCurrencyPrice(currencyRate*btcPrice);
    };
    fetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costPerKWH, hashingPower, poolFee, powerConsumption, selected, currencyRate, btcPrice]);

  React.useEffect(() => {
    let hashing = 0;
    let powerConsumption = 0;
    let costPerKwh = 0.115;
    let poolFee = 1.5;

    document
      .getElementById("hashing")
      ?.addEventListener("updatehashing", function (e: any) {
        hashing = e.detail;
        setHashingPower(hashing);
      });
    document
      .getElementById("powerConsumption")
      ?.addEventListener("updatepowerConsumption", function (e: any) {
        powerConsumption = e.detail;
        setPowerConsumption(powerConsumption);
      });
    document
      .getElementById("costPerKwh")
      ?.addEventListener("updatecostPerKwh", function (e: any) {
        costPerKwh = e.detail;
        setCostPerKWH(costPerKwh);
      });
    document
      .getElementById("poolFee")
      ?.addEventListener("updatepoolFee", function (e: any) {
        poolFee = e.detail;
        setPoolFee(poolFee);
      });
    setHashingPower(hashing);
    setPowerConsumption(powerConsumption);
    setCostPerKWH(costPerKwh);
    setPoolFee(poolFee);
  }, []);

  React.useEffect(() => {
    const fetching = async () => {
      const data = await fetch(1, 0, 0, 0);
      setBtcPrice(data.btcAUD);
    };
    fetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSelectChange(event: any) {
    setSelectedClient(event.target.value);
  }

  let imgUrl = "https://www.cryptocompare.com/media/37746251/btc.png";
  let signs = "Ƀ";
  switch (tab) {
    case "BTC":
      imgUrl = "https://www.cryptocompare.com/media/37746251/btc.png";
      signs = "Ƀ";
      break;
    case "ETH":
      imgUrl = "https://www.cryptocompare.com/media/37746238/eth.png";
      signs = "Ξ";
      break;
    case "ETC":
      imgUrl = "https://www.cryptocompare.com/media/37746862/etc.png";
      signs = "ETC";
      break;
    case "XMR":
      imgUrl = "https://www.cryptocompare.com/media/37746883/xmr.png";
      signs = "XMR";
      break;
    case "ZEC":
      imgUrl = "https://www.cryptocompare.com/media/37746899/zec.png";
      signs = "ZEC";
      break;
    case "DASH":
      imgUrl = "https://www.cryptocompare.com/media/37746893/dash.png";
      signs = "DASH";
      break;
    case "LTC":
      imgUrl = "https://www.cryptocompare.com/media/37746243/ltc.png";
      signs = "Ł";
      break;
    default:
    // code block
  }

  return (
    <React.Fragment>
      <div className="w-full px-0 font-sans">
        <div className="mx-auto rounded">
          <div id="tab-contents" className="py-2 mt-3 bg-white md:mt-0">
            <div id={tab} className="p-4">
              <div className="w-full md:flex md:space-x-10">
                <div className="md:w-1/4 rounded-md">
                  <div className="justify-center shadow-lg grid">
                    <img src={imgUrl} width="100" alt="logo" className="my-1" />
                  </div>
                  <div className="divide-y bg-gray-secondary md:rounded-xl">
                    <div className="text-center py-2.5">
                      <p className="mb-1 text-xs font-normal text-gray-500">
                        Calculated for
                      </p>
                      <p className="text-xs font-bold text-gray-500">
                        1 {tab} = {formatDollars(btcCurrencyPrice)} {currency}
                      </p>
                    </div>
                    <div className="bg-gray-primary md:rounded-b-xl">
                      <div id="inputs" className="p-6">
                        <div className="mb-4">
                          <label
                            htmlFor="price"
                            className="block mb-2 text-sm font-bold text-gray-700"
                          >
                            Hashing Power (TH/s)
                          </label>
                          <div className="relative mt-1 shadow-sm">
                            <input
                              type="number"
                              name="hashing"
                              id="hashing"
                              value={hashingPower}
                              onChange={(e: any) =>
                                setHashingPower(e.target.value)
                              }
                              className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700"
                            htmlFor="username"
                          >
                            Power Consumption (w)
                          </label>
                          <input
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            id="powerConsumption"
                            type="number"
                            placeholder=""
                            value={powerConsumption}
                            onChange={(e: any) =>
                              setPowerConsumption(e.target.value)
                            }
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700"
                            htmlFor="username"
                          >
                            Power Cost per KWh ($)
                          </label>
                          <input
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            id="costPerKWH"
                            type="number"
                            placeholder=""
                            value={costPerKWH}
                            onChange={(e: any) => setCostPerKWH(e.target.value)}
                          />
                        </div>
                        <div className="mb-4">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700"
                            htmlFor="username"
                          >
                            Pool Fee (%)
                          </label>
                          <input
                            className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                            id="poolFee"
                            type="number"
                            value={poolFee}
                            placeholder=""
                            onChange={(e: any) => setPoolFee(e.target.value)}
                          />
                        </div>
                        <div className="mb-0">
                          <label
                            className="block mb-2 text-sm font-bold text-gray-700"
                            htmlFor="username"
                          >
                            Currency
                          </label>
                          <div id="currency" className="relative block">
                            <select onChange={e => setCurrency(e.target.value)} className="block w-full px-3 py-2 pr-8 text-gray-700 bg-white border rounded shadow appearance-none hover:border-gray-500 focus:outline-none focus:shadow-outline">
                              <option>AUD</option>
                              <option>USD</option>
                            </select>
                            <div className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-700 pointer-events-none">
                              <svg className="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-3 md:w-3/4 md:mt-0">
                  <div className="w-full sm:flex md:space-x-5 md:justify-end">
                    <div
                      className={`${
                        color === "profit"
                          ? "bg-profit-primary-bg"
                          : "bg-loss-primary-bg"
                      } text-right py-3 md:px-10 px-5 rounded-xl`}
                    >
                      <span
                        className={`${
                          color === "profit"
                            ? "text-secondary-profit-text"
                            : "text-secondary-loss-text"
                        } bg-white px-3 shadow-lg py-1.5 rounded-lg text-xs font-semibold`}
                      >
                        POTENTIAL POWER TO REVENUE RATIO
                      </span>
                      <div
                        className={`${
                          color === "profit"
                            ? "text-primary-profit-text"
                            : "text-primary-loss-text"
                        } mt-2 text-3xl font-bold`}
                      >
                        {btcData.profitRatioPerDay ==
                          Number.POSITIVE_INFINITY ||
                        btcData.profitRatioPerDay.toFixed(2) == "NaN" ||
                        btcData.profitRatioPerDay == Number.NEGATIVE_INFINITY
                          ? "0.00"
                          : btcData.profitRatioPerDay.toFixed(2)}
                        %
                      </div>
                    </div>
                    <div
                      className={`bg-${color}-primary-bg text-right py-3 md:px-10 px-5 rounded-xl md:mt-0 mt-3`}
                    >
                      <span
                        className={`bg-white text-secondary-${color}-text px-3 shadow-lg py-1.5 rounded-lg text-xs font-semibold`}
                      >
                        POTENTIAL PROFIT PER MONTH
                      </span>
                      <div
                        className={`text-primary-${color}-text mt-2 text-3xl font-bold`}
                      >
                        {formatDollars(btcData.profitInMonthUSD)}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="mt-4 bg-gray-100 grid md:grid-cols-3 md:rounded-xl">
                      <div
                        className={`${
                          color === "profit"
                            ? "bg-profit-secondary-bg"
                            : "bg-loss-secondary-bg "
                        } text-right p-3 relative md:rounded-l-xl`}
                      >
                        <p className="text-sm font-normal text-white">
                          Potential profit per day
                        </p>
                        <span className="text-2xl font-semibold text-white">
                          {formatDollars(btcData.profitInDayUSD)}
                        </span>
                        <div className="text-right">
                          <p className="text-xs font-normal text-white">
                            Pool Fee - {formatDollars(btcData.minedDayPoolFee)}
                          </p>
                        </div>
                        <p
                          className={`${
                            color === "profit"
                              ? "bg-profit-badge-bg"
                              : "bg-loss-badge-bg"
                          } w-16 absolute bottom-0 left-0 text-center text-white text-sm font-normal p-1 md:rounded-tr-xl md:rounded-bl-xl`}
                        >
                          Day
                        </p>
                      </div>
                      <div className="p-4 text-right md:pt-5">
                        <p className="text-sm font-normal text-secondary">
                          Mined/day
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {signs}{" "}
                          {humanNumber(btcData.minedDay, (n: any) =>
                            Number.parseFloat(n).toFixed(4)
                          )}
                        </span>
                      </div>
                      <div className="p-4 text-right md:pt-5 md:pr-10 md:rounded-r-xl">
                        <p className="text-sm font-normal text-secondary">
                          Power cost/day
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {btcData.powerCostDay
                            ? formatDollars(parseFloat(btcData.powerCostDay))
                            : formatDollars(0.0)}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 bg-gray-100 grid md:grid-cols-3 md:rounded-xl">
                      <div
                        className={`bg-${color}-secondary-bg text-right relative p-3 md:rounded-l-xl`}
                      >
                        <p className="text-sm font-normal text-white">
                          Potential profit per week
                        </p>
                        <span className="text-2xl font-semibold text-white">
                          {formatDollars(btcData.profitInWeekUSD)}
                        </span>
                        <div className="text-right">
                          <p className="text-xs font-normal text-white">
                            Pool Fee - {formatDollars(btcData.minedWeekPoolFee)}
                          </p>
                        </div>
                        <p
                          className={`bg-${color}-badge-bg w-16 absolute bottom-0 left-0 text-center text-white text-sm font-normal p-1 md:rounded-tr-xl md:rounded-bl-xl`}
                        >
                          Week
                        </p>
                      </div>
                      <div className="p-4 text-right md:pt-5">
                        <p className="text-sm font-normal text-secondary">
                          Mined/week
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {signs}{" "}
                          {humanNumber(btcData.minedWeek, (n: any) =>
                            Number.parseFloat(n).toFixed(4)
                          )}
                        </span>
                      </div>
                      <div className="p-4 text-right md:pt-5 md:pr-10 md:rounded-r-xl">
                        <p className="text-sm font-normal text-secondary">
                          Power cost/week
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {btcData.powerCostWeek
                            ? formatDollars(parseFloat(btcData.powerCostWeek))
                            : formatDollars(0.0)}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 bg-gray-100 grid md:grid-cols-3 md:rounded-xl">
                      <div
                        className={`bg-${color}-secondary-bg border-${color}-primary-border text-right relative p-4 border-b-2 md:rounded-l-xl border border-${color}-600`}
                      >
                        <p className="text-sm font-normal text-white">
                          Potential profit per month
                        </p>
                        <span className="text-2xl font-semibold text-white">
                          {formatDollars(btcData.profitInMonthUSD)}
                        </span>
                        <div className="text-right">
                          <p className="text-xs font-normal text-white">
                            Pool Fee -{" "}
                            {formatDollars(btcData.minedMonthPoolFee)}
                          </p>
                        </div>
                        <p
                          className={`bg-${color}-badge-bg w-16 absolute bottom-0 left-0 text-center text-white text-sm font-normal p-1 md:rounded-tr-xl md:rounded-bl-xl`}
                        >
                          Month
                        </p>
                      </div>
                      <div className="p-4 text-right md:pt-5">
                        <p className="text-sm font-normal text-secondary">
                          Mined/month
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {signs}{" "}
                          {humanNumber(btcData.minedMonth, (n: any) =>
                            Number.parseFloat(n).toFixed(4)
                          )}
                        </span>
                      </div>
                      <div className="p-4 text-right md:pt-5 md:pr-10 md:rounded-r-xl">
                        <p className="text-sm font-normal text-secondary">
                          Power cost/month
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {btcData.powerCostMonth
                            ? formatDollars(parseFloat(btcData.powerCostMonth))
                            : formatDollars(0.0)}
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 bg-gray-100 grid md:grid-cols-3 md:rounded-xl">
                      <div
                        className={`bg-${color}-secondary-bg text-right p-4 relative md:rounded-l-xl`}
                      >
                        <p className="text-sm font-normal text-white">
                          Potential profit per year
                        </p>
                        <span className="text-2xl font-semibold text-white">
                          {formatDollars(btcData.profitInYearUSD)}
                        </span>
                        <div className="text-right">
                          <p className="text-xs font-normal text-white">
                            Pool Fee - {formatDollars(btcData.minedYearPoolFee)}
                          </p>
                        </div>
                        <p
                          className={`bg-${color}-badge-bg w-16 absolute bottom-0 left-0 text-center text-white text-sm font-normal p-1 md:rounded-tr-xl md:rounded-bl-xl`}
                        >
                          Year
                        </p>
                      </div>
                      <div className="p-4 text-right md:pt-5">
                        <p className="text-sm font-normal text-secondary">
                          Mined/year
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {signs}{" "}
                          {humanNumber(btcData.minedYear, (n: any) =>
                            Number.parseFloat(n).toFixed(4)
                          )}
                        </span>
                      </div>
                      <div className="p-4 pt-4 text-right md:pr-10 md:pt-5 md:rounded-r-xl">
                        <p className="text-sm font-normal text-secondary">
                          Power cost/year
                        </p>
                        <span
                          className={`text-primary-${color}-text text-2xl font-semibold`}
                        >
                          {btcData.powerCostYear
                            ? formatDollars(parseFloat(btcData.powerCostYear))
                            : formatDollars(0.0)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='flex p-4'>
            <div className='w-full text-xs text-gray-500 xl:w-1/2'>
              <span>
              {disclaimer} 
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CryptoMiningRigs;
